<template>
    <div class="form-group">
        <label :for="id">Header</label>
        <input
            :id="id"
            v-model="header"
            type="text"
            class="form-control"
            dir="auto"
            placeholder="Header"
        />
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true,
            default: ''
        }
    },

    data() {
        return {
            header: '',
            id: 'header'
        };
    },

    watch: {
        header() {
            this.$emit('input', this.header);
        }
    },

    created() {
        this.id = Math.random()
            .toString(36)
            .substring(2, 15)
            .match(/[a-zA-Z]+/g)
            .join('');
    },

    mounted() {
        this.header = this.value;
    }
};
</script>
