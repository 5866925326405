<template>
    <wysiwyg-editor v-model="text" label="Text" />
</template>

<script>
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        WysiwygEditor
    },

    props: {
        value: {
            type: String,
            required: true,
            default: () => ''
        }
    },

    data() {
        return {
            text: ''
        };
    },

    watch: {
        text() {
            this.$emit('input', this.text);
        }
    },

    created() {
        this.text = this.value;
    }
};
</script>
