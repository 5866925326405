<template>
    <layout>
        <div>
            <page-header :title="title" :items="items" />

            <div v-if="!editMode || (editMode && article.id)" class="row">
                <div class="col-xl-9 mb-3">
                    <div class="card h-100">
                        <div class="card-body">
                            <add-edit-article-form
                                v-model="article"
                                :edit-mode="editMode"
                                :validation="$v"
                                @on-title-change="onTitleChange"
                            />
                        </div>
                    </div>
                </div>

                <div class="col-xl-3 mb-3">
                    <div class="card">
                        <div class="card-body">
                            <div class="mb-3">
                                <a
                                    v-if="editMode"
                                    :href="previewUrl"
                                    class="btn btn-primary btn-static waves-effect waves-light mr-1 mb-1"
                                    target="_blank"
                                    @click="onPreviewButtonClick"
                                >
                                    <i class="far fa-eye" />
                                    Preview
                                </a>
                                <button
                                    class="btn btn-success btn-static waves-effect waves-light mb-1"
                                    @click="submitHandler"
                                >
                                    <i class="fas fa-save" />
                                    Save
                                </button>
                            </div>

                            <item-information
                                :key="itemInformationKey"
                                :edit-mode="editMode"
                                :sections="sections"
                                :slug="slug"
                                :slug-error="slugError"
                                :item="article"
                                :tags="article.tags"
                                :options="options"
                                :featured-image="article.imageId"
                                :image-author="article.imageAuthor"
                                @on-slug-change="onSlugChange"
                                @on-featured-image-change="onFeaturedImageChange"
                                @on-image-author-change="onImageAuthorChange"
                                @on-delete-confirm="onDeleteConfirm"
                                @meta-change="onMetaChange"
                                @on-tags-change="onTagsChange"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import { required, integer } from 'vuelidate/lib/validators';
import config from '@/config';
import AddEditArticleForm from '@components/articles/AddEditArticleForm';
import ItemInformation from '@components/elements/ItemInformation';

export default {
    page: {
        title: 'Add new article'
    },

    validations: {
        article: {
            title: {
                required
            },
            author: {
                required
            },
            reading_time: {
                required,
                integer
            }
        },
        slug: {
            required
        }
    },

    components: {
        AddEditArticleForm,
        ItemInformation
    },

    data() {
        return {
            title: 'Add article',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Articles',
                    to: '/articles'
                },
                {
                    text: 'Add',
                    active: true
                }
            ],
            options: {
                slugBasicUrl: 'articles'
            },
            editMode: false,
            article: {
                banner: {},
                right_banner: {},
                fixed_button: {},
                fixed_button_mobile: {},
                recommendedArticles: []
            },
            slug: '',
            id: null,
            slugExists: false,
            articleValueStringify: '',
            itemInformationKey: 0
        };
    },

    computed: {
        sections() {
            const sections = {
                permalink: true,
                tags: true
            };

            if (this.editMode) {
                sections.status = true;
            }

            return sections;
        },
        slugError() {
            if (this.$v.slug.$error) {
                return 'Slug is required.';
            }

            if (this.slugExists) {
                return 'Slug already exists.';
            }

            return '';
        },
        previewUrl() {
            return `${config.frontendUrl}/articles/${this.slug}`;
        }
    },

    async created() {
        this.id = this.$route.params.id;

        if (this.id) {
            this.editMode = true;
            this.title = 'Edit article';
            this.items[this.items.length - 1].text = 'Edit';

            try {
                this.article = await this.getArticle(this.id);
                this.slug = this.article.slug;

                this.articleValueStringify = JSON.stringify(this.article);
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        }
    },

    methods: {
        ...mapActions({
            addArticle: 'articles/store',
            deleteArticle: 'articles/delete',
            getArticle: 'articles/show',
            updateArticle: 'articles/update'
        }),

        async submitHandler() {
            this.slugExists = false;

            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                this.article.slug = this.slug;

                const tags = this.article.tags.map(item => item.id);
                const recommendedArticles = this.article.recommendedArticles.map(item => item.id);

                if (!this.editMode) {
                    const added = await this.addArticle({
                        ...this.article,
                        tags,
                        recommendedArticles
                    });

                    this.$router.push(`/articles/${added.id}/edit`);
                } else {
                    const article = await this.updateArticle({
                        id: this.id,
                        article: { ...this.article, tags, recommendedArticles }
                    });

                    this.article.updated_at = article.updated_at;
                    this.articleValueStringify = JSON.stringify(this.article);

                    this.$toaster('Article has been updated!');
                }
            } catch (error) {
                console.error(error);

                if (
                    error.response.status === 422 &&
                    error.response.data.errors &&
                    error.response.data.errors.slug
                ) {
                    this.slugExists = true;

                    return;
                }

                this.$toasterError();
            }
        },

        async onDeleteConfirm() {
            try {
                await this.deleteArticle(this.id);

                this.$router.push('/articles');
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        onPreviewButtonClick(e) {
            if (this.articleValueStringify !== JSON.stringify(this.article)) {
                e.preventDefault();

                this.$toasterWarning('Please save changes before preview!');
            }
        },

        onTitleChange(name) {
            if (!this.editMode && name) {
                this.slug = name
                    .replace(/<\/?[^>]+(>|$)/g, '')
                    .replace(/&nbsp;/g, '')
                    .replace(/&amp;/g, '&');

                this.itemInformationKey += 1;
            }
        },

        onSlugChange(slug) {
            this.slug = slug;
            this.$v.slug.$reset();
        },

        onTagsChange(tags){
            this.article.tags = [...tags];
        },

        onFeaturedImageChange(image) {
            this.article.imageId = image;
        },

        onImageAuthorChange(imageAuthor) {
            this.article.imageAuthor = imageAuthor;
        },

        onMetaChange(meta) {
            this.article.meta = { ...meta };
        }
    }
};
</script>
