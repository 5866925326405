<template>
    <div class="row">
        <div class="form-group col-12">
            <wysiwyg-editor
                v-model="article.title"
                label="Title"
                @input="$emit('on-title-change', article.title)"
            />
        </div>
        <div class="form-group col-12">
            <wysiwyg-editor
                v-model="article.description"
                label="Description"
            />
        </div>
        <media-chooser
            v-model="article.thumbnail"
            label="Blog Thumbnail"
            class="col-4"
        />
        <media-chooser
            v-model="article.image"
            label="Top Image"
            class="col-4"
        />
        <media-chooser
            v-model="article.mobile_image"
            label="Top Image Mobile"
            class="col-4"
        />
        <div class="form-group col-4">
        </div>
        <div class="form-group col-4">
            <b-form-checkbox
                v-model="article.image_fit_to_grid"
                name="sticky"
                switch
            >
                Fit to Grid
            </b-form-checkbox>
        </div>
        <div class="form-group col-6">
            <label for="author">Author</label>
            <input
                id="author"
                v-model="article.author"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': validation.article.author.$error
                }"
                placeholder="Author"
            />
        </div>
        <div class="form-group col-6">
            <label for="reading_time">Reading Time</label>
            <input
                id="reading_time"
                v-model="article.reading_time"
                type="text"
                class="form-control"
                :class="{
                    'is-invalid': validation.article.reading_time.$error
                }"
                placeholder="Reading time"
            />
        </div>
        <div class="form-group col-6">
            <b-form-checkbox
                v-model="article.author_enabled"
                name="sticky"
                switch
            >
                Author Enabled
            </b-form-checkbox>
        </div>
        <div class="form-group col-6">
            <b-form-checkbox
                v-model="article.reading_time_enabled"
                name="sticky"
                switch
            >
                Reading Time Enabled
            </b-form-checkbox>
        </div>
        <div class="form-group col-12 mt-2">
            <b-form-checkbox
                v-model="article.social_buttons_enabled"
                name="sticky"
                switch
            >
                Share to Social Media Buttons
            </b-form-checkbox>
        </div>
        <div class="form-group col-12">
            <b-form-checkbox
                v-model="article.newsletter"
                name="sticky"
                switch
            >
                Newsletter Component
            </b-form-checkbox>
        </div>

        <div v-if="!editMode || (editMode && article.id)" class="col-12 mt-2">
            <content-builder v-model="article.content" label="Content" />
        </div>

        <div class="col-12 mt-5">
            <h6 class="col-12 text-uppercase text-center bg-light p-2">
                Right Banner
            </h6>
        </div>
        <div class="form-group col-12 mt-2">
            <b-form-checkbox
                v-model="article.right_banner.enabled"
                name="sticky"
                switch
            >
                Enabled
            </b-form-checkbox>
        </div>
        <div class="form-group col-12">
            <b-form-checkbox
                v-model="article.right_banner.sticky"
                name="sticky"
                switch
            >
                Sticky
            </b-form-checkbox>
        </div>
        <media-chooser
            v-model="article.right_banner.image"
            label="Image"
            class="col-12"
        />
        <div class="form-group col-12">
            <label>
                Image Link
            </label>
            <input
                v-model="article.right_banner.link"
                type="text"
                class="form-control"
                :lable="`SideTitle`"
            />
        </div>

        <div class="col-12 mt-3">
            <h6 class="col-12 text-uppercase text-center bg-light p-2">
                Bottom Banner
            </h6>
        </div>

        <div class="form-group col-12 mt-2">
            <b-form-checkbox
                v-model="article.banner.enabled"
                name="sticky"
                switch
            >
                Enabled
            </b-form-checkbox>
        </div>
        <div class="form-group col-12">
            <label>
                Title
            </label>
            <input
                v-model="article.banner.title"
                type="text"
                class="form-control"
                :lable="`SideTitle`"
            />
        </div>
        <div class="form-group col-6">
            <label>
                Button Text
            </label>
            <input
                v-model="article.banner.buttonText"
                type="text"
                class="form-control"
                :lable="`SideTitle`"
            />
        </div>
        <div class="form-group col-6">
            <label>
                Button Link
            </label>
            <input
                v-model="article.banner.buttonLink"
                type="text"
                class="form-control"
                :lable="`SideTitle`"
            />
        </div>

        <div class="col-6 p-0 m-0 row">
            <div class="col-12 mt-2">
                <h6 class="col-12 text-uppercase text-center bg-light p-2">
                    Fixed Button Mobile
                </h6>
            </div>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox
                    v-model="article.fixed_button_mobile.enabled"
                    name="sticky"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label>Background Color</label>
                <color-picker
                    :key="article.fixed_button_mobile.backgroundColor"
                    v-model="article.fixed_button_mobile.backgroundColor"
                    :color="article.fixed_button_mobile.backgroundColor"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Background Text
                </label>
                <input
                    v-model="article.fixed_button_mobile.backgroundText"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-12">
                <label>Button Color</label>
                <color-picker
                    :key="article.fixed_button_mobile.buttonColor"
                    v-model="article.fixed_button_mobile.buttonColor"
                    :color="article.fixed_button_mobile.buttonColor"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Text
                </label>
                <input
                    v-model="article.fixed_button_mobile.buttonText"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Link
                </label>
                <input
                    v-model="article.fixed_button_mobile.buttonLink"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
        </div>

        <div class="col-6 p-0 m-0 row">
            <div class="col-12 mt-2">
                <h6 class="col-12 text-uppercase text-center bg-light p-2">
                    Fixed Button Desktop
                </h6>
            </div>
            <div class="form-group col-12 mt-2">
                <b-form-checkbox
                    v-model="article.fixed_button.enabled"
                    name="sticky"
                    switch
                >
                    Enabled
                </b-form-checkbox>
            </div>
            <div class="form-group col-12">
                <label>Background Color</label>
                <color-picker
                    :key="article.fixed_button.backgroundColor"
                    v-model="article.fixed_button.backgroundColor"
                    :color="article.fixed_button.backgroundColor"
                />
            </div>
            <div class="form-group col-12">
                <label>
                    Background Text
                </label>
                <input
                    v-model="article.fixed_button.backgroundText"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-12">
                <label>Button Color</label>
                <color-picker
                    :key="article.fixed_button.buttonColor"
                    v-model="article.fixed_button.buttonColor"
                    :color="article.fixed_button.buttonColor"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Text
                </label>
                <input
                    v-model="article.fixed_button.buttonText"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
            <div class="form-group col-6">
                <label>
                    Button Link
                </label>
                <input
                    v-model="article.fixed_button.buttonLink"
                    type="text"
                    class="form-control"
                    :lable="`SideTitle`"
                />
            </div>
        </div>

        <div class="form-group col-6" />

        <div class="form-group col-6">
            <b-form-checkbox
                v-model="article.fixed_button.arrowEnabled"
                name="sticky"
                switch
            >
                Arrow Enabled
            </b-form-checkbox>
        </div>

        <div class="col-12 mt-3">
            <h6 class="col-12 text-uppercase text-center bg-light p-2">
                Recommended Articles
            </h6>

            <div class="d-flex flex-wrap mt-4">
                <div class="form-group col-6">
                    <b-form-select
                        v-model="selectedRecommendedArticle"
                        :options="selectOptions"
                    />
                </div>

                <div class="form-group col-6 d-flex align-items-stretch">
                    <button
                        type="button"
                        class="mx-2 btn btn-sm btn-success waves-effect waves-light"
                        @click="onAddRecommendedArticle"
                    >
                        <i class="mdi mdi-plus" />
                        Add Article
                    </button>
                </div>
            </div>

            <draggable-list
                :key="article.recommendedArticles.length"
                :items="article.recommendedArticles"
                @element-deleted="onDeleteRecommendedArticle"
            >
                <span slot-scope="row">
                    <span>
                        {{ row.element.slug }}
                    </span>
                    <span style="color: grey">
                        - {{ row.element.title }}
                    </span>
                </span>
            </draggable-list>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import ContentBuilder from '@components/elements/ContentBuilder';
import DraggableList from '@components/elements/DraggableList';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import WysiwygEditor from '@components/elements/WysiwygEditor';

export default {
    components: {
        ContentBuilder,
        DraggableList,
        MediaChooser,
        ColorPicker,
        WysiwygEditor
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => {}
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        validation: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            article: {
                banner: {},
                right_banner: {},
                fixed_button: {},
                fixed_button_mobile: {},
                recommendedArticles: []
            },
            articles: [],
            selectedRecommendedArticle: {}
        };
    },

    computed: {
        selectOptions() {
            return this.articles.map(article => {
                return {
                    text: `${article.slug} - (${article.title})`,
                    value: article
                }
            })
        }
    },

    watch: {
        article() {
            this.$emit('input', this.article);
        }
    },

    mounted() {
        this.article = { ...this.value };

        if (!this.article.content) {
            this.article.content = [];
        }

        this.fetchArticles();
    },

    methods: {
        ...mapActions({
            getArticles: 'articles/index',
        }),

        async fetchArticles() {
            try {
                const { rows } = await this.getArticles({
                    currentPage: null,
                    perPage: null
                });

                this.articles = rows;
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        onDeleteRecommendedArticle(id) {
            this.article.recommendedArticles = this.article.recommendedArticles.filter(item => item.id !== id);
        },

        onAddRecommendedArticle() {
            if (
                !this.selectedRecommendedArticle.id ||
                this.article.recommendedArticles.find(
                    article => article.id === this.selectedRecommendedArticle.id
                )
            ) {
                return;
            }

            this.article.recommendedArticles.push(
                this.selectedRecommendedArticle
            );
        }
    }
};
</script>
