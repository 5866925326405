<template>
    <div>
        <media-chooser :value="image" type="image" @input="onImageChange" />
        <form class="row col-12">
            Image alignment:
            <div v-for="(option, index) in options" :key="index">
                <input
                    v-model="imageAlignment"
                    type="radio"
                    name="alignment"
                    :value="option.value"
                    class="ml-2"
                />
                {{ option.text }}
            </div>
        </form>

        <form class="row col-12 mt-2">
            Image link:
            <input
                v-model="imageLink"
                type="text"
                class="form-control mt-1"
                dir="auto"
                placeholder="Image Link"
            />
        </form>
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },

    data() {
        return {
            image: {},
            imageAlignment: 'center',
            imageLink: '',
            options: [
                { value: 'center', text: 'Center' },
                { value: 'left', text: 'Left' },
                { value: 'right', text: 'Right' }
            ]
        };
    },

    watch: {
        imageAlignment() {
            this.image.alignment = this.imageAlignment;

            this.$emit('input', this.image);
        },

        imageLink() {
            this.image.link = this.imageLink;

            this.$emit('input', this.image);
        }
    },

    mounted() {
        this.image = { ...this.value };
        this.imageAlignment = this.image.alignment || 'center';
        this.imageLink = this.image.link;
    },

    methods: {
        onImageChange(image) {
            if (image.id) {
                this.image = { ...image };
            } else {
                this.image = {};
            }

            this.image.alignment = this.imageAlignment;
            this.image.link = this.imageLink;

            this.$emit('input', this.image);
        }
    }
};
</script>
