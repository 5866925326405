<template>
    <div>
        <media-chooser
            :value="images"
            type="image"
            multiple-mode
            @input="onImagesChange"
        />
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        value: {
            type: Array,
            required: true,
            default: () => []
        }
    },

    data() {
        return {
            images: []
        };
    },

    mounted() {
        this.images = [...this.value];
    },

    methods: {
        onImagesChange(images) {
            this.images = images.length ? images : [];

            this.$emit('input', this.images);
        }
    }
};
</script>
