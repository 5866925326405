<template>
    <div>
        <div>
            <h6 class="col-12 text-uppercase text-center bg-light p-2 mb-3">
                {{ label }}
            </h6>
        </div>
        <div>
            <draggable
                :key="contentKey"
                :list="value"
                handle=".handle"
                @start="drag = true"
                @end="onMoved"
            >
                <div v-for="(item, index) in content" :key="index" class="my-1">
                    <div class="mb-2">
                        <button
                            class="handle btn btn-danger waves-effect waves-light mr-1"
                            type="button"
                        >
                            <i class="fas fa-arrows-alt" />
                        </button>
                        <button
                            v-if="index"
                            class="btn btn-danger waves-effect waves-light mr-1"
                            type="button"
                            @click="moveElement(index, index - 1)"
                        >
                            <i class="fas fa-arrow-up" />
                        </button>
                        <button
                            v-if="index !== value.length - 1"
                            class="btn btn-danger waves-effect waves-light mr-1"
                            type="button"
                            @click="moveElement(index, index + 1)"
                        >
                            <i class="fas fa-arrow-down" />
                        </button>
                        <button
                            class="btn btn-danger waves-effect waves-light mr-1"
                            type="button"
                            @click="deleteElement(index)"
                        >
                            <i class="far fa-trash-alt" />
                        </button>
                    </div>
                    <component
                        :is="`content-section-${item.key}`"
                        v-model="item.value"
                        @input="$emit('input', content)"
                    />
                    <h6 class="col-12 text-uppercase bg-light p-2" />
                </div>
            </draggable>
        </div>
        <div class="mt-2">
            <div class="col-12 d-flex flex-wrap justify-content-around mb-3">
                <button
                    v-if="contentSections.header"
                    class="btn btn-success waves-effect waves-light mr-1 content-button mt-1"
                    type="button"
                    @click="addContent('header')"
                >
                    <i class="fas fa-plus-circle" /> Header
                </button>
                <button
                    v-if="contentSections.text"
                    class="btn btn-success waves-effect waves-light mr-1 content-button mt-1"
                    type="button"
                    @click="addContent('text')"
                >
                    <i class="fas fa-plus-circle" /> Text
                </button>
                <button
                    v-if="contentSections.image"
                    class="btn btn-success waves-effect waves-light mr-1 content-button mt-1"
                    type="button"
                    @click="addContent('image')"
                >
                    <i class="fas fa-plus-circle" /> Image
                </button>
                <button
                    v-if="contentSections.gallery"
                    class="btn btn-success waves-effect waves-light mr-1 content-button mt-1"
                    type="button"
                    @click="addContent('gallery')"
                >
                    <i class="fas fa-plus-circle" /> Gallery
                </button>
                <button
                    v-if="contentSections.video"
                    class="btn btn-success waves-effect waves-light mr-1 content-button mt-1"
                    type="button"
                    @click="addContent('video')"
                >
                    <i class="fas fa-plus-circle" /> Video
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from './contents-builder-elements/Header';
import Image from './contents-builder-elements/Image';
import Text from './contents-builder-elements/Text';
import Video from './contents-builder-elements/Video';
import Gallery from './contents-builder-elements/Gallery';
import Draggable from 'vuedraggable';

export default {
    components: {
        Draggable,
        'content-section-header': Header,
        'content-section-image': Image,
        'content-section-text': Text,
        'content-section-video': Video,
        'content-section-gallery': Gallery
    },

    props: {
        value: {
            type: Array,
            required: true,
            default: () => []
        },
        label: {
            type: String,
            required: false,
            default: 'Content'
        },
        sections: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            drag: false,
            content: [],
            contentKey: 0
        };
    },

    computed: {
        contentSections() {
            return {
                header: true,
                text: true,
                image: true,
                video: true,
                gallery: true,
                ...this.sections
            };
        }
    },

    created() {
        this.content = [...this.value];
    },

    methods: {
        addContent(type) {
            let value = {};

            if (type === 'gallery') {
                value = [];
            } else if (type === 'header' || type === 'text') {
                value = '';
            } else if (type === 'video') {
                value.linkOnly = true;
            }

            const newItem = {
                key: type,
                value
            };

            this.content = [...this.content, { ...newItem }];

            this.$emit('input', this.content);
            this.contentKey += 1;
        },

        moveElement(from, to) {
            this.content.splice(to, 0, this.content.splice(from, 1)[0]);

            this.$emit('input', [...this.content]);
            this.contentKey += 1;
        },

        onMoved() {
            this.drag = false;
            this.contentKey += 1;
        },

        deleteElement(index) {
            this.content.splice(index, 1);

            this.$emit('input', [...this.content]);
            this.contentKey += 1;
        }
    }
};
</script>
